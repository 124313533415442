* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: $baseFontSize;
}

html.movemenu {
  overflow: hidden;
}

body {
  box-sizing: border-box;
  background: #fff;
  color: $baseFont;
  font-size: $baseFontSize;
  line-height: 1.6;
  font-family: "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
