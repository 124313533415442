//
// Display utilities
//
$display-types: ('inline', 'block', 'none', 'flex');

@each $type in $display-types {
  .d-#{$type} {
    display: #{$type};
  }
}

@each $type in $display-types {
  .d-sm-#{$type} {
    @include screen-sm {
      display: #{$type};
    }
  }
}

@each $type in $display-types {
  .d-md-#{$type} {
    @include screen-md {
      display: #{$type};
    }
  }
}

@each $type in $display-types {
  .d-lg-#{$type} {
    @include screen-lg {
      display: #{$type};
    }
  }
}

//
// Spacing utilities
//
$spacer-interval: 0.5;
$spacing-types: (
  m: margin,
  p: padding,
);
$spacing-directions: (
  'x': (left right),
  'y': (top bottom),
  't': (top),
  'b': (bottom),
  'l': (left),
  'r': (right),
);
$spacing-sizes: (0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);

@each $spacing-type-symbol, $spacing-type in $spacing-types {
  @each $size in $spacing-sizes {
    .#{$spacing-type-symbol}-#{$size} {
      #{$spacing-type}: #{$size / 10}rem;
    }
  }

  @each $size in $spacing-sizes {
    .#{$spacing-type-symbol}-sm-#{$size} {
      @include screen-sm {
        #{$spacing-type}: #{$size / 10}rem;
      }
    }
  }

  @each $size in $spacing-sizes {
    .#{$spacing-type-symbol}-md-#{$size} {
      @include screen-md {
        #{$spacing-type}: #{$size / 10}rem;
      }
    }
  }

  @each $size in $spacing-sizes {
    .#{$spacing-type-symbol}-lg-#{$size} {
      @include screen-lg {
        #{$spacing-type}: #{$size / 10}rem;
      }
    }
  }

  @each $direction-symbol, $direction-props in $spacing-directions {
    @each $size in $spacing-sizes {
      .#{$spacing-type-symbol}#{$direction-symbol}-#{$size} {
        @each $direction-prop in $direction-props {
          #{$spacing-type}-#{$direction-prop}: #{$size / 10}rem;
        }
      }
    }

    @each $size in $spacing-sizes {
      .#{$spacing-type-symbol}#{$direction-symbol}-sm-#{$size} {
        @include screen-sm {
          @each $direction-prop in $direction-props {
            #{$spacing-type}-#{$direction-prop}: #{$size / 10}rem;
          }
        }
      }
    }

    @each $size in $spacing-sizes {
      .#{$spacing-type-symbol}#{$direction-symbol}-md-#{$size} {
        @include screen-md {
          @each $direction-prop in $direction-props {
            #{$spacing-type}-#{$direction-prop}: #{$size / 10}rem;
          }
        }
      }
    }

    @each $size in $spacing-sizes {
      .#{$spacing-type-symbol}#{$direction-symbol}-lg-#{$size} {
        @include screen-lg {
          @each $direction-prop in $direction-props {
            #{$spacing-type}-#{$direction-prop}: #{$size / 10}rem;
          }
        }
      }
    }
  }
}

//
// text-align utilities
//
.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

//
// font-size utilities
//
.fs-1 {
  font-size: 2rem;

  @include screen-sm {
    font-size: 4rem;
  }
}

.fs-2 {
  font-size: 1.5rem;
  line-height: 1.2;

  @include screen-sm {
    font-size: 2rem;
  }
}

.fs-3 {
  font-size: 1.3rem;
  line-height: 1.2;

  @include screen-sm {
    font-size: 1.6rem;
  }
}

.fs-4 {
  font-size: 1rem;
  line-height: 1.2;
}

.position-fixed {
  position: fixed;
}

//
// justify-content utilities
//
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}
