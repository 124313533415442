@import 'Stylesheets/refosumu/base/v2/mixins';

.site-header {
  background: #fff;
  width: 100%;

  @include screen-lg {
    width: 1000px;
    margin: auto;
  }

  &__pc {
    height: 90px;
    margin-bottom: 10px;
  }

  &__top {
    width: 100%;
    height: 28px;
    margin-top: 5px;
    padding-right: 20px; // メニューと縦の並びを合わせる対応
  }

  &__top-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }

  &__home {
    width: 46px;
    height: 24px;
  }

  &__home-link {
    // NOTE: wordpressコラムとフォントを合わせるため
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    text-decoration: underline;
    color: #3C3C3C;
    line-height: 24px;
  }

  &__about_refosumu {
    width: 112px;
    height: 24px;
  }

  &__about_refosumu-link {
    // NOTE: wordpressコラムとフォントを合わせるため
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    text-decoration: underline;
    color: #3C3C3C;
    line-height: 24px;
  }

  &__client-recruiting {
    width: 138px;
    height: 27px;
    background-color: #F3F7F8;
    border: #6AD6E8 1px solid;
    border-radius: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  &__client-recruiting-link {
    // NOTE: wordpressコラムとフォントを合わせるため
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-size: 16px;
    color: #3C3C3C;
    line-height: 27px;
  }

  &__bottom {
    width: 100%;
    height: 62px;
    padding-top: 8px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px; // メニューと縦の並びを合わせる対応
    padding-right: 20px; // メニューと縦の並びを合わせる対応
  }

  &__sing {
    display: flex;
    align-items: center;
  }

  &__tel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__tel-number {
    display: flex;
    align-items: center;
  }

  &__tel-number-icon {
    margin-right: 5px;
    width: 42.67px;
    height: 27.84px;
  }

  &__telephone-number {
    // NOTE: wordpressコラムとフォントを合わせるため
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-size: 25px;
    text-decoration: underline;
    color: #0088ff;
  }

  &__tel-reception {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__tel-reception-time {
    // NOTE: wordpressコラムとフォントを合わせるため
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-size: 14px;
    text-align: center;
  }

  &__tel-reception-ok {
    margin-left: 0.5rem;
    // NOTE: wordpressコラムとフォントを合わせるため
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-size: 14px;
    text-align: center;
  }

  &__sp {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__sp-logo {
    margin-left: 5px;
    margin-top: 9px;
    margin-bottom: 10px;
  }

  &__menu {
    margin-top: 5px;
    margin-bottom: 6px;
    color: #fff;
    background-color: #6ad6e8;
    height: 100%;
    aspect-ratio: 1 / 1;
    line-height: 1.25;
    text-align: center;
  }

  &__humberger-icon {
    align-content: center;
    align-items: center;
    color: inherit;
    display: inline-flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    justify-content: center;
    text-decoration: none;
    appearance: none;
    background-color: transparent;
    border: none;
    box-shadow: none;

    // 横線3本をスタイルする部分
    &__lines {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 18px;
      margin-bottom: 5px;

      .line {
        width: 20px;
        height: 2px;
        background-color: #fff;
      }
    }

    // "MENU"の文字をスタイルする部分
    &__label {
      display: block;
      font-size: 10px;
      transform: scale(.9);
      width: 100%;
    }
  }
}
