@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-partner-companies {
  font-family: 'Noto Sans JP', sans-serif;
  padding: 40px 20px;

  &__container {
    max-width: 1000px;
    margin: 50px auto 0;
  }

  &__header {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    width: 100%;

    @include screen-md {
      margin-top: 60px;
    }
  }

  &__service-name-box {
    position: absolute;
    left: 35px;
    top: -20px;
    z-index: 1;
    width: 127px;
    height: 47px;
    transform: matrix(1, -0.09, 0.09, 1, 0, 0);

    @include screen-md {
      width: 221px;
      height: 74px;
      left: 250px;
      top: -40px;
      transform: matrix(1, -0.05, 0.05, 1, 0, 0);
    }
  }

  &__service-name {
    background-color: #FEEC01;
    padding: 4px 12px;
    font-size: 15px;
    font-weight: bold;
    transform: rotate(-3deg);
    display: inline-block;
    position: relative;

    @include screen-md {
      font-size: 28px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid #FEEC01;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 15px;
    position: relative;
    display: inline-block;
    color: #3C3C3C;

    @include screen-md {
      font-size: 38px;
    }
  }

  &__description {
    text-align: center;
    color: #000000B3;
    margin-bottom: 30px;
    font-size: 16px;

    @include screen-md {
      margin-bottom: 20px;
    }
  }

  &__logos {
    background-image: url('./images/logo_all_sp.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 90%;
    height: 180px;
    margin: 0 auto;

    @include screen-md {
      background-image: url('./images/logo_all_pc.png');
      max-width: 1000px;
      height: 111px;
    }
  }
}
