@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-title-board {
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    &-pc {
      display: none;

      @include screen-md {
        display: block;
        max-width: 550px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &-sp {
      display: block;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;

      @include screen-md {
        display: none;
      }
    }

    &-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      width: 100%;
      height: 110px;
      position: relative;
      background-image: url('./images/h2_bg_pc.png');

      &--sp {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
      }

      &--pc {
        height: 170px;
        margin-top: -35px;
      }
    }

    &-text {
      position: relative;
      font-size: 20px;
      font-weight: bold;
      color: #3C3C3C;
      text-align: center;
      padding: 30px 0 20px;
      width: 100%;

      @include screen-md {
        font-size: 28px;
        padding: 40px 0 20px;
      }
    }

    &-highlight {
      display: inline-block;
      background-color: #FEEC01;
      padding: 0 20px;
      margin-top: 4px;
      color: #3C3C3C;
      font-weight: bold;
      font-size: 20px;

      @include screen-md {
        font-size: 38px;
      }
    }
  }
}
