@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-main-visual {
  position: relative;
  width: 100%;
  height: 500px;
  background: transparent url('./images/mv.png');
  background-size: cover;
  background-position: center;

  @include screen-md {
    height: 650px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-image: url('./images/wave.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  @media (max-width: 750px) {
    &::after {
      height: 60px;
    }
  }

  &__container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px;
    text-align: center;
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    font-family: 'Noto Sans CJK JP', sans-serif;
    font-weight: 700;
    color: #006AB8;
    line-height: 1.4;
    margin-bottom: 20px;

    @include screen-md {
      font-size: 56px;
    }

    span {
      white-space: nowrap;
      background-color: #fff;
      padding: 5px 5px;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-family: 'Noto Sans CJK JP', sans-serif;
    font-weight: 500;
    color: #006AB8;
    margin-top: 16px;
    margin-bottom: 40px;

    @include screen-md {
      font-size: 27px;
      margin-top: 20px;
    }
  }

  &__cta {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &__cta-microcopy {
    position: absolute;
    width: 200px;
    height: 25px;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 1rem;
    border: 2px solid #fff;
    background-color: #ff8032;
    border-radius: 30px;
  }

  &__cta-button {
    position: relative;
    max-width: 350px;
    width: 100%;
    height: 70px;
    margin: 0 auto;
    font-size: 1.6rem;
    font-weight: bold;
    border: 2px solid #fff;
    background: linear-gradient(180deg, rgba(74,214,237,1) 0%, rgba(0,136,255,1) 100%);
    border-radius: 6px;
    transition: opacity 0.3s;

    @include screen-md {
      max-width: 400px;
      width: 100%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__cta-text {
    color: #fff;
    line-height: 70px;
    padding: 0 25px 0 0;

    span {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin-right: 25px;
      line-height: 54px;
      color: #ff8032;
      font-size: 1.3rem;
      background-color: #fff;
      border-radius: 50px;
    }

    img {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
