$use_html5: true;

//--------------------------------------------------
// supported browser
//--------------------------------------------------
$support-ie6: false;
$support-ie7: false;
$support-ie8: true;
$support-ie9: true;
$support-ie10: true;
$support-ie11: true;

$support-ios4: false;
$support-ios5: true;
$support-ios6: true;

$support-android2: false;
$support-android3: true;
$support-android4: true;

//-------------------------------------------------
// グリッドサイズ
//-------------------------------------------------
$column-width: 54px;
$gutter-width: 32px;

// コンテンツ全体のサイズ
$content-width: 1000px;

// レスポンシブフラグ
$support-responsive: true;

$ipad-width: 1024px;
$sp-width: 768px;
$iphone6plus-width: 450px;
$iphone6-width: 375px;
$iphone5-width: 320px;
$i-width: 320px;
$tablet-width: 768px;
$desktop-width: $content-width;
$rowBreak-width: 800px;

// ------------------------------------------------
// font size
// ------------------------------------------------
$baseFontSizeNum: 14;
$baseFontSize: $baseFontSizeNum + px;
$fontSizeSubTitle: 16px;
$fontSizeMainTitle: 32px;
$fontSizeMainSpTitle: 20px;

// ------------------------------------------------
// 共通マージン
// ------------------------------------------------
$mgn01: 20px;
