@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-blue-dotted-line {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    top: 0;
    left: 0;
    background-image: url('./images/blue_line.png');
    background-size: cover;
  }
}
