@mixin screen-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin screen-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin screen-lg {
  @media screen and (min-width: 1000px) {
    @content;
  }
}
