@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-national-subsidies {
  padding: 79px 16px 50px;
  font-family: 'Noto Sans JP', sans-serif;

  @include screen-md {
    padding: 58px 16px 50px;
  }

  &__container {
    max-width: 1000px;
    margin: 0 auto;
  }

  &__header {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    width: 100%;

    @include screen-md {
      margin-top: 100px;
    }
  }

  &__highlight-title-box {
    position: absolute;
    left: 45px;
    top: -20px;
    z-index: 1;
    width: 83px;
    height: 43px;
    transform: matrix(1, -0.09, 0.09, 1, 0, 0);

    @include screen-md {
      width: 153px;
      height: 70px;
      left: 265px;
      top: -40px;
      transform: matrix(1, -0.05, 0.05, 1, 0, 0);
    }
  }

  &__highlight-title {
    background-color: #FEEC01;
    padding: 4px 12px;
    font-size: 15px;
    font-weight: bold;
    transform: rotate(-3deg);
    display: inline-block;
    position: relative;
    color: #3C3C3C;

    @include screen-md {
      font-size: 28px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid #FEEC01;
    }
  }

  &__non-highlight-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 15px;
    position: relative;
    display: inline-block;
    color: #3C3C3C;
    margin-bottom: 18px;

    @include screen-md {
      font-size: 38px;
      margin-bottom: 30px;
    }
  }

  &__description {
    font-size: 14px;
    color: #3C3C3C;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 36px;
    text-align: left;

    @include screen-md {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }

  &__content {
    margin-bottom: 73px;

    @include screen-md {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 40px 80px;
      margin-bottom: 100px;
      padding-left: 30px;
    }
  }

  &__region {
    margin-bottom: 20px;

    @include screen-md {
      margin-bottom: 0;

      // 北海道・東北
      &:nth-child(1) {
        grid-area: 1 / 1;
      }
      // 中部・北陸
      &:nth-child(2) {
        grid-area: 2 / 1;
      }
      // 中国・四国
      &:nth-child(3) {
        grid-area: 3 / 1;
      }
      // 関東
      &:nth-child(4) {
        grid-area: 1 / 2;
      }
      // 関西
      &:nth-child(5) {
        grid-area: 2 / 2;
      }
      // 九州・沖縄
      &:nth-child(6) {
        grid-area: 3 / 2;
      }
    }
  }

  &__region-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;

    @include screen-md {
      margin-bottom: 20px;
    }
  }

  &__prefecture-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__prefecture-link {
    text-align: left;
    text-decoration: underline;
    font-size: 16px;
    color: #0088FF;

    &:hover {
      opacity: 0.8;
    }
  }

  &__footer {
    text-align: center;
    position: relative;
  }

  &__search-button {
    display: inline-block;
    box-sizing: border-box;
    width: 277px;
    height: 50px;
    background-color: #0088FF;
    border: 1px solid #0088FF;
    border-radius: 30px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    padding: 12px 32px;
    font-weight: 400;


    &:hover {
      opacity: 0.8;
    }
  }

  &__character {
    width: 48px;
    height: 48px;
    background-image: url('./images/icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -37px;
    right: 70px;

    @include screen-md {
      width: 53px;
      height: 40px;
      top: -40px;
      right: 400px;
    }
  }
}
