@import 'Stylesheets/refosumu/base/v2/mixins';

.site-slider-navigation {
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 30;
  background-color: white;
  // NOTE: wordpressコラムとフォントを合わせるため
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;

  &--show {
    display: block;
  }

  &__body {
    position: fixed;
    width: 88%;
    top: 0;
    right: 0;
    background: url("images/sp-menu-bg-1.png");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.15));
    padding: calc(903 / 356.92 * 100%) 5% 0 9%;
    z-index: 5;
  }

  // 閉じるボタン
  &__close-button {
    position: absolute;
    top: 10px;
    padding: 0.5rem;
    text-align: center;
    line-height: 1;
    z-index: 10;
    cursor: pointer;
    left: -40px;
  }

  &__close-button-icon {
    content: "";
    background-image: url("images/sp-menu-close.png");
    width: 24px;
    height: 30px;
    background-size: 100% 100%;
  }

  .p-spMenu {
    //スクロールバー
    &__inner {
      position: absolute;
      top: 5px;
      overflow-y: auto;
      padding-right: 7%;
      height: 100vh;
      padding-bottom: 300px;
    }

    .title-font-size {
      font-size: 17.5px;

      @media (max-width: 375px) {
        font-size: 17px;
      }
    }

    .item-font-size {
      font-size: 14px;

      @media (max-width: 375px) {
        font-size: 13.5px;
      }
    }

    .margin-top_and_bottom {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    // リフォーム箇所
    .part-type-title-block {
      margin-top: 2rem;
    }

    .is-layout-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr); // 2列
      gap: 0.5em;

      figure {
        margin: 0;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    // リンクリスト（リフォーム事例など）
    .link-list {
      list-style: none;
      padding: 0;
      margin: 0;

      .link_list__items {
        margin-top: 15px;
        margin-bottom: 8px;

        .link_list__link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #3c3c3c;
          text-decoration: none;
          position: relative;
          padding-bottom: 4px;

          &:hover {
            color: #000;
          }

          // アンダーラインをホワイトに設定
          &::after {
            content: '';
            position: absolute;
            bottom: -4px; /* 下部の白線 */
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #fff;
          }

          .link_list__text {
            margin-right: auto;
          }

          .link_list__icon {
            width: 6px;
            height: auto;
            margin-right: 10px;
          }
        }
      }
    }

    // お問い合わせ
    .contact-block {
      margin-bottom: 1em;
      line-height: 1.8;
      max-width: 100%;
      padding: 0;
      background-color: #fff;
      border-radius: 6px;

      .contact_items {
        margin-top: 0!important;
        margin-bottom: 0!important;
        padding: 0 19px 16px 19px;
        border-radius: 6px;

        &__inner-container {
          margin: 0;
          padding: 0;

          .cta-block {
            .contact-header {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              margin-bottom: -25px;

              p {
                margin: 0;
              }

              .character-image {
                width: 60px;
                height: 60px;
                background-image: url("images/cta_navi_sp.png");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                z-index: 2;
              }
            }
          }
        }
      }

      .has-text-align-center {
        text-align: center;
      }

      .cta-btn {
        position: relative;
        z-index: 1;

        a {
          align-items: center;
          background: linear-gradient(0deg, #ffec00, #fff255, #fffcd3);
          color: #3c3c3c;
          border: 2px solid #fff;
          border-radius: 6px;
          margin-top: 14px;
          font-size: 14px;
          display: inline-flex;
          padding: .5em 1em;
          font-weight: 700;
          transition: transform .25s,box-shadow .25s,-webkit-transform .25s;
          z-index: 1;

          @media (max-width: 375px) {
            font-size: 13px;
          }
        }

        span {
          background-color: #fff;
          border-radius: 6px;
          padding: 2px 5px;
          margin-right: 5px;
        }
      }

      .cta-icon {
        width: 6px;
        height: auto;
        margin-left: 20px;
      }

      .estimate-icon {
        margin-left: 15px;
      }

      .tel-block {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        margin-bottom: 0;
        align-items: center;
      }

      .tel-icon img {
        width: 52px;
        height: 30px;
      }

      .tel-number {
        margin-left: 5px;

        span {
          font-size: 22px;
        }

        a {
          color: #0088ff;
          text-decoration: underline;
          line-height: 1;
        }
      }

      .office-hours-block {
        margin-top: 3px;
      }

      .office-hours-text {
        font-size: 12px;
        color: #3c3c3c;
      }
    }

    /* よくあるご質問用の専用クラス */
    .faq-link-list {
      list-style: none;
      padding: 0;
      margin: 0;

      .link_list__items {
        position: relative;
        padding: 8px 0; /* テキスト上下のスペースを調整 */
        margin-bottom: 8px;

        .link_list__link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #3c3c3c;
          text-decoration: none;
          position: relative;
          padding-bottom: 4px;

          &:hover {
            color: #000;
          }

          // 白線
          &::before,
          &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #fff;
          }

          &::before {
            top: -8px; /* 上部の白線 */
          }

          &::after {
            bottom: -4px; /* 下部の白線 */
          }

          .link_list__text {
            margin-right: auto;
          }

          .link_list__icon {
            width: 6px;
            height: auto;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.site-global-navigation {
  // NOTE: wordpressコラムとフォントを合わせるため
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;

  .pc-menu {
    background-color: #0088FF;
    display: flex;
    justify-content: center;
  }

  .pc-menu-items {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    width: 100%;
    height: 57px;
    padding: 2px 0;

    p {
      position: relative;
      width: calc(100% / 8);
      text-align: center;
      color: #fff;

      &::before {
        content: '';
        position: absolute;
        left: -1px;
        top: 10px;
        width: 1px;
        height: 35px;
        background-color: #fff;
      }

      &:hover {
        background-color: #fff;

        &::before {
          content: none;
        }

        a {
          color: #0088ff;
        }

        a > i {
          &.menu-icon.icon-bath {
            background-image: url("images/icon_bath_blue.png");
          }

          &.menu-icon.icon-kitchen {
            background-image: url("images/icon_kitchen_blue.png");
          }

          &.menu-icon.icon-washroom {
            background-image: url("images/icon_washroom_blue.png");
          }

          &.menu-icon.icon-toilet {
            background-image: url("images/icon_toilet_blue.png");
          }
        }
      }

      &:last-child {
        &::before {
          content: none;
        }

        background-color: #ffec00;

        a {
          color: #3c3c3c;
          flex-direction: column;
          height: 53px;
          line-height: 1.6;
          position: relative;
          justify-content: center;
          text-align: center;

          &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url("images/cta_pc.png");
            background-position: right center;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 1;
          }


          span {
            z-index: 2;
          }
        }
      }

      a {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: bold;
      }
    }

    i {
      &.menu-icon {
        display: block;
        margin-right: 5px;
        background-size: 100% 100%;

        &.icon-bath {
          width: 26px;
          height: 19px;
          background-image: url("images/icon_bath.png");
        }

        &.icon-kitchen {
          width: 24px;
          height: 17px;
          background-image: url("images/icon_kitchen.png");
        }

        &.icon-washroom {
          width: 18px;
          height: 19px;
          background-image: url("images/icon_washroom.png");
        }

        &.icon-toilet {
          width: 15px;
          height: 19px;
          background-image: url("images/icon_toilet.png");
        }
      }
    }
  }

  .cta-link {
    .white-label {
      font-size: 12px;
      padding: 3px 8px;
      border-radius: 10px;
      line-height: 1;
      background-color: white;
    }
  }
}
