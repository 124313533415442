@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-simple-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 277px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #0088FF;
  border-radius: 30px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 600;
  color: #0088FF;

  &:hover {
    opacity: 0.8;
  }

  @include screen-md {
    width: 277px;
    height: 50px;
  }

}
