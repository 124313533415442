@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-blue-line {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    top: 0;
    left: 0;
    background-image: url('./images/blue_line.png');
    background-size: cover;
  }
}

.refosumu-v2-flow {
  padding: 50px 16px;
  background-color: #f0faff;

  &__container {
    max-width: 865px;
    margin: 0 auto;
  }

  &__title {
    position: relative;
    margin-bottom: 22px;
    text-align: center;
    font-size: 24px;

    @include screen-md {
      font-size: 38px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 30px;
      height: 40px;
      background-image: url('./images/icon_beginner.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      top: calc(50% - 20px);
    }
  }

  &__lead {
    margin-bottom: 26px;
  }

  &__wrap {
    position: relative;
    display: flex;
    align-items: flex-start;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      left: 20px;
      top: 20px;
      border-left: 2px dashed #707070;

      @include screen-md {
        left: 43px;
      }
    }
  }

  &__step {
    position: relative;
    margin-top: 15px;
    padding: 2px 0px 2px 6px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    background-color: #51be4b;
    border-radius: 20px 0px 0px 20px;

    @include screen-md {
      min-width: 82px;
      margin-top: 20px;
      padding: 2px 7px 2px 12px;
      font-size: 18px;
    }

    &::after {
      position: absolute;
      content: '';
      top: 0px;
      right: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #51be4b;
      border-right: 0;

      @include screen-md {
        right: -16px;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 16px solid #51be4b;
      }
    }
  }

  &__box {
    width: 100%;
    padding: 10px 20px 20px;
    margin-left: -10px;
    margin-bottom: 30px;
    background-color: #fff;
    border: 3px solid #51BE4B;
    border-radius: 6px;

    @include screen-md {
      padding: 20px 30px;
    }
  }

  &__heading {
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #f4f4f4;

    @include screen-md {
      padding-left: 10px;
      font-size: 18px;
    }
  }

  &__wrap--last {
    margin-bottom: 60px;

    @include screen-md {
      margin-bottom: 120px;
    }

    &::before {
      content: none;
    }

    .refosumu-v2-flow {
      &__step {
        color: #3C3C3C;
        background-color: #feec01;

        &::after {
          right: -10px;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid #feec01;

          @include screen-md {
            right: -16px;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
            border-left: 16px solid #feec01;
          }
        }
      }

      &__box {
        padding: 10px 0 20px 20px;
        display: flex;
        width: auto;
        border: 3px solid #feec01;

        @include screen-md {
          padding: 20px 30px;
        }
      }

      &__heading {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;

        @include screen-md {
          br {
            display: none;
          }
        }
      }

      &__image {
        position: relative;
        width: 150px;

        @include screen-md {
          width: 178px;
        }

        img {
          position: absolute;
          width: 100%;
          max-height: none;
        }
      }
    }
  }

  &__cta-button {
    position: relative;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border-radius: 6px;
    background-color: #0088ff;
    border-bottom: 3px solid #05579f;
    transition: opacity 0.3s;

    @include screen-md {
      font-size: 28px;
      padding: 10px 10px 10px 30px;
    }

    span {
      position: absolute;
      top: calc(50% - 13px);
      left: 18px;
      padding: 3px 8px;
      color: #0088ff;
      font-size: 12px;
      border-radius: 6px;
      background-color: #fff;

      @include screen-md {
        font-size: 18px;
        top: calc(50% - 16px);
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      right: 15px;
      width: 11px;
      height: 2px;
      background-color: #fff;
      transform-origin: calc(100% - 1px) 50%;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
