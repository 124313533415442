@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-service-renewal {
  padding: 50px 16px;
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #FAFAFA;

  @include screen-md {
    padding: 48px 0 81px;
  }

  &__container {
    @include screen-md {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #51BE4B;
    margin-bottom: 16px;

    @include screen-md {
      margin-bottom: 10px;
    }
  }

  &__main-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #3C3C3C;
    margin-bottom: 17px;

    @include screen-md {
      font-size: 38px;
      margin-bottom: 30px;

      br {
        display: none;
      }
    }
  }

  &__description {
    font-size: 16px;
    line-height: 25px;
    color: #3C3C3C;
    text-align: left;
    font-weight: 400;
    margin-bottom: 20px;

    @include screen-md {
      font-size: 17px;
      margin-bottom: 30px;
    }
  }

  &__image {
    margin: 0 auto 20px;
  }

  &__image-container {
    text-align: center;
    margin-bottom: 20px;

    &:nth-child(2) {
      @include screen-md {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
      }
    }
  }

  &__before {
    border: 3px solid #51be4b;
    color: #707070;
    font-weight: bold;
    background: #fff;

    @include screen-md {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }

    &-heading {
      color: #fff;
      line-height: 35px;
      font-size: 1.15rem;
      text-align: center;
      background-color: #51be4b;

      @include screen-md {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 108px;
        font-size: 1.45rem;
      }
    }

    &-container {
      padding: 20px 30px 0;

      &:nth-child(2) {
        @include screen-md {
          display: flex;
          align-items: center;
        }
      }

      &:last-child {
        padding: 0 20px 0;

        @include screen-md {
          padding: 20px 35px 5px 0;
          width: calc(100% - 380px);
        }
      }

      @include screen-md {
        padding:0;
      }
    }

    &-logo {
      max-width: 180px;
      margin: 0 auto 20px;
    }

    &-lead {
      text-align: center;
      font-size: large;
      margin-bottom: 15px;

      @include screen-md {
        font-size: 1.45rem;
        margin-bottom: 10px;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 0 5px;
      font-size: 1.15rem;
      text-align: center;
      border-top: 2px solid #f2f2f2;
      list-style: none;

      li {
        width: calc(100% / 3 - 7px);
        margin-bottom: 15px;
        line-height: 37px;
        background-color: #f7f7f7;
        border: 2px solid #f2f2f2;

        @include screen-md {
          width: calc(100% / 6 - 7px);
        }
      }
    }
  }

  &__after {
    border: 3px solid #feec01;
    color: #707070;
    font-weight: bold;
    background: #fff;

    @include screen-md {
      display: flex;
      align-items: stretch;
    }

    &-heading {
      color: #3c3c3c;
      line-height: 35px;
      font-size: 1.15rem;
      text-align: center;
      background-color: #feec01;

      @include screen-md {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 108px;
        font-size: 1.45rem;
      }
    }

    &-container {
      padding: 20px 30px 0;
      width: 100%;

      @include screen-md {
        padding: 10px 30px 0;
      }
    }

    &-logo {
      max-width: 180px;
      margin: 0 auto 15px;

      @include screen-md {
        max-width: 160px;
      }
    }

    &-lead {
      font-size: 1.45rem;
      text-align: center;

      @include screen-md {
        font-size: 1.45rem;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 0 5px;
      font-size: 1.15rem;
      text-align: center;
      border-top: 2px solid #f2f2f2;
      list-style: none;

      @include screen-md {
        padding: 0 0 5px;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 7px;
        border-top: none;
      }

      li {
        width: calc(100% / 2 - 8px);
        margin-bottom: 15px;
        line-height: 37px;

        @include screen-md {
          width: calc(100% / 4 - 3px);
          max-width: 86px;
        }
      }
    }

    &--r {
      @include screen-md {
        width: 600px;
      }

      .refosumu-v2-service-renewal__after {
        &-lead {
          color: #0387ff;
        }

        &-list {
          li {
            background-color: #e0fcfd;
          }
        }
      }
    }

    &--n {
      @include screen-md {
        width: 335px;
      }

      .refosumu-v2-service-renewal__after {
        &-lead {
          color: #e5a322;
        }

        &-list {
          li {
            border: 2px solid #f9f9f9;
          }
        }

        &-logo {
          @include screen-md {
            max-width: 133px;
          }
        }
      }
    }
  }

  &__footer-text {
    font-size: 16px;
    line-height: 25px;
    color: #3C3C3C;
    text-align: left;
    font-weight: 400;

    @include screen-md {
      font-size: 17px;
    }
  }
}
