@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-helpful-column {
  padding: 24px 15px;
  background: #FFFFFF;
  font-family: 'Noto Sans CJK JP', sans-serif;

  @include screen-md {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
  }

  // セクションタイトルのスタイリング
  &__title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #3C3C3C;

    @include screen-md {
      font-size: 38px;
    }
  }

  // タブエリアのスタイリング
  &__tabs {
    display: flex;
    justify-content: center;
    gap: 7px;
    margin: 0 -16px;
    padding: 0 16px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    border-bottom: 2px solid #2DBED6;

    @include screen-md {
      border-bottom: 3px solid #2DBED6;
      gap: 20px;
    }

    // スクロールバーを非表示
    &::-webkit-scrollbar {
      display: none;
    }
  }

  // 個別のタブスタイル
  &__tab {
    flex: 0 0 auto;
    width: 81px;
    padding: 12px 8px;
    background: #F9F9F9;
    border-radius: 6px 6px 0 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 1;

    @include screen-md {
      width: 210px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 0 16px;
    }

    // アクティブなタブのスタイル
    &--active {
      background: #E1F7FB;
      border: 2px solid #2DBED6;
      border-radius: 6px 6px 0 0;
      margin-bottom: -1px;

      @include screen-md {
        border: 3px solid #2DBED6;
      }

      // アクティブタブの下部ボーダー調整
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background: #E5F6FF;

        @include screen-md {
          bottom: -2px;
          height: 2px;
        }
      }
    }
  }

  // タブアイコンのスタイリング
  &__tab-icon {
    width: 24px;
    height: 16px;
    margin: 0 auto 4px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;

    @include screen-md {
      margin: 0;
    }

    // 各タブの非アクティブ時のアイコン
    &--bath { background-image: url('./images/bath_inactive.svg'); }
    &--kitchen { background-image: url('./images/kitchen_inactive.svg'); }
    &--lavatory { background-image: url('images/lavatory_inactive.svg'); }
    &--toilet { background-image: url('./images/toilet_inactive.svg'); }

    // アクティブ時のアイコン切り替え
    .refosumu-v2-helpful-column__tab--active & {
      &--bath { background-image: url('./images/bath_active.svg'); }
      &--kitchen { background-image: url('./images/kitchen_active.svg'); }
      &--lavatory { background-image: url('images/lavatory_active.svg'); }
      &--toilet { background-image: url('./images/toilet_active.svg'); }
    }
  }

  // タブテキストのスタイリング
  &__tab-text {
    font-size: 14px;
    color: #C8C8C8;

    .refosumu-v2-helpful-column__tab--active & {
      color: #2DBED6;

      @include screen-md {
        font-size: 16px;
      }
    }
  }

  // コンテンツエリアのスタイリング
  &__content {
    margin-top: 21px;
  }

  // コラムリストのスタイリング
  &__column-list {
    display: none;

    // アクティブな場合の表示制御
    &[data-visible="true"] {
      display: block;

      @include screen-md {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  // コラムアイテムのスタイリング
  &__column-item {
    margin-bottom: 16px;
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0 2px 2px #0000001C;
    display: flex;
    align-items: center;

    @include screen-md {
      width: 320px;
      height: 100px;
    }
  }

  // 画像コンテナのスタイリング
  &__image-container {
    flex: 2;
    height: 120px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include screen-md {
      flex: 2;
      height: 100px;
    }
  }

  // コラム画像のスタイリング
  &__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    // 各カテゴリーの画像マッピング
    &.mizumawari_bath-image {
      &.column-2020 { background-image: url('./images/mizumawari_bath/2020.png'); }
      &.column-2209 { background-image: url('./images/mizumawari_bath/2209.png'); }
      &.column-2158 { background-image: url('./images/mizumawari_bath/2158.png'); }
      &.column-2234 { background-image: url('./images/mizumawari_bath/2234.png'); }
      &.column-24660 { background-image: url('./images/mizumawari_bath/24660.png'); }
    }

    &.mizumawari_kitchen-image {
      &.column-2013 { background-image: url('./images/mizumawari_kitchen/2013.png'); }
      &.column-2023 { background-image: url('./images/mizumawari_kitchen/2023.png'); }
      &.column-2035 { background-image: url('./images/mizumawari_kitchen/2035.png'); }
      &.column-28256 { background-image: url('./images/mizumawari_kitchen/28256.png'); }
      &.column-33307 { background-image: url('./images/mizumawari_kitchen/33307.jpg'); }
    }

    &.mizumawari_lavatory-image {
      &.column-2269 { background-image: url('./images/mizumawari_lavatory/2269.png'); }
      &.column-28197 { background-image: url('./images/mizumawari_lavatory/28197.png'); }
      &.column-69892 { background-image: url('./images/mizumawari_lavatory/69892.png'); }
      &.column-71949 { background-image: url('./images/mizumawari_lavatory/71949.png'); }
      &.column-72019 { background-image: url('./images/mizumawari_lavatory/72019.png'); }
    }

    &.mizumawari_toilet-image {
      &.column-2267 { background-image: url('./images/mizumawari_toilet/2267.png'); }
      &.column-2519 { background-image: url('./images/mizumawari_toilet/2519.png'); }
      &.column-2528 { background-image: url('./images/mizumawari_toilet/2528.png'); }
      &.column-66973 { background-image: url('./images/mizumawari_toilet/66973.png'); }
      &.column-68571 { background-image: url('./images/mizumawari_toilet/68571.png'); }
    }
  }

  // テキストコンテンツエリアのスタイリング
  &__text-content {
    flex: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    min-width: 0;

    @include screen-md {
      padding: 10px 0;
    }
  }

  // リンクのスタイリング
  &__column-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
  }

  // リンクタイトルのスタイリング
  &__link-title {
    flex: 1;
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    text-decoration: underline;
    color: #0088FF;
    margin-right: 10px;
    overflow: hidden;
    display: -webkit-box;

    @include screen-md {
      font-size: 11px;
      font-weight: 300;
      line-height: 18px;
      color: #707070;
      text-decoration: none;
    }
  }

  // 矢印アイコンのスタイリング
  &__arrow {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    background-image: url('./images/blue_arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include screen-md {
      width: 15px;
      height: 15px;
      background-image: url('./images/black_arrow.svg');
    }
  }

  // 「もっと詳しく」セクションのスタイリング
  &__more-info {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background: #F0FAFF;
    border-radius: 30px;
    margin-top: 24px;
    cursor: pointer;
    height: 50px;

    @include screen-md {
      margin: 24px auto 0;
      width: 345px;
      height: 50px;
    }
  }

  // 「もっと詳しく」アイコンとリンクのスタイリング
  &__more-info-icon {
    position: absolute;
    bottom: 0;
    left: 16px;
    flex: 0 0 auto;
    width: 50px;
    height: 35px;
    background-image: url('./images/refosumu_icon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__more-info-link {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    margin-left: 60px;
  }

  &__more-info-text {
    font-size: 14px;
    font-weight: bold;
    color: #0088FF;
  }

  &__more-info-arrow {
    flex: 0 0 auto;
    width: 25px;
    height: 25px;
    background-image: url('./images/arrow_around_white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
