@import "font-awesome/css/font-awesome.css";

//
// ベーススタイル
//

// v1
@import "../../stylesheets/refosumu/base/util/site-color";
@import "../../stylesheets/refosumu/base/util/setting";
@import "../../stylesheets/refosumu/base/common/reset";
@import "../../stylesheets/refosumu/base/base/root";
@import "../../stylesheets/refosumu/base/base/inline";

// v2
@import "../../stylesheets/refosumu/base/v2/mixins.scss";
@import "../../stylesheets/refosumu/base/v2/utilities.scss";
