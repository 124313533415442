@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-reform-parts {
  max-width: 1000px;
  padding: 0;
  margin: 0 auto;
  font-family: 'Noto Sans CJK JP', sans-serif;
  overflow: hidden;

  @include screen-md {
    padding: 60px 20px 0 20px;
  }

  // リフォームしたい箇所を選ぶ
  .title {
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: bold 24px/38px 'Noto Sans CJK JP', sans-serif;
    color: #3C3C3C;
    position: relative;

    &::before {
      content: 'リフォームしたい\A箇所を選ぶ';
      white-space: pre;
      display: block;
      position: relative;
      z-index: 1;
    }

    > span {
      display: none;
    }

    &::after {
      content: '';
      width: 100px;
      height: 100px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      background: url('./images/search.png') center/contain no-repeat;
    }

    @include screen-md {
      font-size: 38px;
      margin-bottom: 40px;
      gap: 10px;

      &::before {
        content: none;
      }

      > span {
        display: block;
      }

      &::after {
        position: static;
        width: 150px;
        height: 150px;
        transform: none;
      }
    }
  }

  .reform-items {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .reform-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 10px 70px;
    justify-content: space-between;

    // SP用の画像サイズ
    .image-wrapper {
      flex: 0 0 calc(50% - 5px);
      width: calc(50% - 5px);
      height: 150px;
      margin-bottom: 50px;
      background: #fff;
      overflow: hidden;
    }

    // PC用の画像サイズ
    @include screen-md {
      padding-top: 30px;
      padding-bottom: 50px;

      .image-wrapper {
        flex: 0 0 500px;
        height: 400px;
        border-radius: 50%;
        margin-bottom: 0;

        &::before {
          content: '';
          position: absolute;
          top: -10%;
          left: -10%;
          width: 120%;
          height: 120%;
          border-radius: 50%;
          z-index: -1;
        }
      }
    }

    // 点線の区切り
    &:not(:last-child)::after {
      content: '';
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      border-bottom: 2px dashed #E8F5FF;

      @include screen-md {
        bottom: 0;
      }
    }

    // 偶数番目のレイアウト
    &:nth-child(even) {
      flex-direction: row-reverse;
      transform: translateX(10px);  // SPでの右寄せ

      .content {
        padding-right: 0;
        margin-top: 0;
        width: calc(50% - 5px);
      }

      @include screen-md {
        transform: none;  // PCでは右寄せ解除

        .content {
          padding-left: 0;
          margin-top: -80px;
        }
      }
    }

    // 奇数番目のレイアウト
    &:nth-child(odd) {
      flex-direction: row;

      .content {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        width: calc(50% - 5px);
      }

      @include screen-md {
        .content {
          margin-top: -200px;
        }
      }
    }

    // トイレリフォーム（4番目）の特別なレイアウト
    &:nth-child(4) {
      .image-wrapper {
        flex: 0 0 180px;
        width: 180px;
        height: 180px;

        @include screen-md {
          flex: 0 0 500px;
          width: auto;
          height: 400px;
        }
      }
    }

    .content {
      flex: 0 0 calc(50% - 5px);
      margin-top: 0;

      @include screen-md {
        flex: 1;
      }
    }

    // ◯◯リフォームのスタイル
    .name {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 10px;
      text-decoration: underline #0088FF;

      a {
        color: #0088FF;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 8px;

        @include screen-md {
          font-size: 30px;
        }

        &::after {
          content: '';
          width: 16px;
          height: 16px;
          background: url('./images/arrow.png') center/contain no-repeat;

          @include screen-md {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    // 平均費用のスタイル
    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      font-weight: bold;

      @include screen-md {
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }

      .label {
        background: #FDEC02;
        padding: 4px 8px;
        font-size: 14px;
        border-radius: 5px;
        color: #3C3C3C;

        @include screen-md {
          padding: 8px 12px;
        }
      }

      .amount-wrapper {
        display: flex;
        align-items: baseline;
        gap: 2px;

        // 〜 のスタイル
        .amount:first-child {
          margin-left: 5px;

          &::after {
            content: '〜';
            color: #707070;
            font-size: 16px;
            margin-top: 0;
            margin-left: 5px;

            @include screen-md {
              font-size: 20px;
              margin-top: 10px;
              margin-left: 10px;
            }
          }
        }
      }

      .amount {
        color: #707070;
        font-size: 26px;
        margin-left: 0;

        @include screen-md {
          font-size: 40px;
        }
      }

      .unit {
        color: #707070;
        font-size: 14px;
        margin-top: 0;
        margin-left: 4px;

        @include screen-md {
          font-size: 20px;
          margin-top: 10px;
        }
      }
    }

    // タグのスタイル
    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 15px;
      position: relative;
      margin-bottom: 30px;

      .item-icon-right,
      .item-icon-left {
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: -70px;
        background-size: contain;

        @include screen-md {
          width: 96px;
          height: 100px;
          bottom: -120px;
        }
      }

      .item-icon-right {
        right: 30px;

        @include screen-md {
          right: 50px;
        }
      }

      .item-icon-left {
        @include screen-md {
          width: 144px;
          height: 100px;
        }
      }
    }

    .tag {
      background: #F0FAFF;
      color: #3C3C3C;
      font-size: 11px;
      padding: 3px 10px;

      @include screen-md {
        padding: 4px 12px;
        font-size: 14px;
      }
    }
  }

  // 画像の設定
  .reform-item {
    &:nth-child(1) .image-wrapper {
      background: url('./images/bath.png') center/cover no-repeat;
    }

    &:nth-child(2) .image-wrapper {
      background: url('./images/kitchen.png') center/cover no-repeat;
    }

    &:nth-child(3) .image-wrapper {
      background: url('./images/washroom.png') center/cover no-repeat;
    }

    &:nth-child(4) .image-wrapper {
      background: url('./images/toilet.png') center/cover no-repeat;
    }
  }

  // アイコンの設定
  .reform-item {
    &:nth-child(1) .tags .item-icon-right {
      background: url('./images/bath_icon.png') center/contain no-repeat;
    }

    &:nth-child(2) .tags .item-icon-left {
      background: url('./images/kitchen_icon.png') center/contain no-repeat;
    }

    &:nth-child(3) .tags .item-icon-right {
      background: url('./images/washroom_icon.png') center/contain no-repeat;
    }

    &:nth-child(4) .tags .item-icon-left {
      background: url('./images/toilet_icon.png') center/contain no-repeat;
    }
  }

  // 洗面台リフォームの特別なスタイル
  .washroom-reform {
    .content {
      margin-left: 0;

      @include screen-md {
        margin-left: 50px;
      }
    }
  }
}
