@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-floating {
  display: none;
  font-family: 'Noto Sans JP', sans-serif;
  position: relative;
  z-index: 2147483647;

  &__box {
    background-image: url('./images/blue_tile.png');
    background-color: white;
    background-size: cover;
    padding: 13px 5px;
    position: fixed;
    width: 100%;
    height: 89px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483647;

    @include screen-md {
      height: 98px;
    }
  }

  &__box-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 81px;
    margin: 0 15px;
    position: relative;
    border-radius: 6px 6px 0 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      opacity: 0.61;
      border-radius: 6px 6px 0 0;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    @include screen-md {
      height: 91px;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &__icon {
    width: 59px;
    height: 65px;
    align-self: flex-end;
    left: -1rem;

    @include screen-md {
      width: 97px;
      height: 93px;
      position: relative;
      left: 20px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;

    @include screen-md {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0 10px 0 30px;
    }
  }

  &__lead {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    font-weight: bold;

    @include screen-md {
      font-size: 24px;
      margin: 0;
    }
  }

  &__lead-text,
  &__part-type {
    margin: 0;
    font-size: 14px;
    font-weight: bold;

    @include screen-md {
      font-size: 26px;
    }
  }

  &__part-type {
    color: #FD4B41;
  }

  &__lead-text {
    color: #3C3C3C;
  }

  &__button {
    background: #FFEC00;
    box-shadow: 0 4px 0 #C9BB00;
    border-radius: 6px;
    cursor: pointer;
    width: 95%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::after {
      background-image: url('./images/arrow.png');
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
    }

    @include screen-md {
      width: 320px;
      height: 68px;
      background: transparent linear-gradient(0deg, #FFEC00 0%, #FFF255 53%, #FFFCD3 100%) 0% 0% no-repeat padding-box;
      box-shadow: inset 0px 0px 1px #00000029, 0px 0px 2px #00000017;
      border: 4px solid #FFFFFF;
      border-radius: 6px;
      margin-left: 2rem;

      &::after {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__free-label {
    background-color: #FFFFFF;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    color: #3C3C3C;
    padding: 2px 9px;
    margin-right: 12px;

    @include screen-md {
      font-size: 22px;
      padding: 5px 17px;
      margin-right: 16px;
    }
  }

  &__free-text {
    font-size: 14px;
    font-weight: bold;
    color: #3C3C3C;

    @include screen-md {
      font-size: 22px;
    }
  }
}
