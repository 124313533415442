$link_opacity_level: 0.8;

img {
  vertical-align: bottom;
  max-width: 100%;
  max-height: 100%;
}

a {
  color: $linkColor;
  text-decoration: none;

  // border-bottom: 1px solid $link-color
  //&:hover, &:focus, &:active, &:visited
  //  color: $linkColor
  //  text-decoration: none
  //  opacity: .6
  &:hover {
    opacity: $link_opacity_level;

    img {
      opacity: $link_opacity_level;
    }
  }
}

strong {
  font-weight: bold;
}
