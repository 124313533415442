@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-feature {
  padding: 24px 16px 50px 16px;
  background-color: #FEFDE6;
  font-family: 'Noto Sans JP', sans-serif;

  @include screen-md {
    padding: 40px 0 60px;
  }

  &__container {
    margin: 0 5px;

    @include screen-md {
      max-width: 1000px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  &__title_board {
    margin: -20px auto 42px;

    @include screen-md {
      margin: 0 auto 40px;
    }
  }

  // "特徴"
  &__items {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;

    @include screen-md {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      margin-bottom: 40px;
    }
  }

  &__item {
    background-color: #fff;
    border: 1px solid #F4F4F4;

    @include screen-md {
      min-height: 280px;
      display: flex;
      flex-direction: column;
    }
  }

  // "特徴"のヘッダー
  &__header {
    background-color: #FFE9DB;
    border-radius: 5px 5px 0 0;
    color: #333;
    height: 57px;
    display: flex;
    align-items: center;
    padding-left: 14px;

    @include screen-md {
      height: 65px;
      padding-left: 24px;
    }

    &-text {
      font-size: 16px;
      font-weight: bold;

      @include screen-md {
        font-size: 22px;
      }

      &--accent {
        color: #3C3C3C;
      }

      &--main {
        color: #FF8032;
      }
    }
  }

  // "特徴"の内容
  &__content {
    padding: 10px 14px 20px;

    @include screen-md {
      padding: 24px;
      flex: 1;
      flex-direction: column;
    }

    &-row {
      display: flex;
      gap: 20px;
      margin-bottom: 16px;

      @include screen-md {
        gap: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // "特徴"の説明文章
  &__text {
    font-size: 13px;
    line-height: 25px;
    color: #3C3C3C;
    flex: 1;

    @include screen-md {
      font-size: 18px;
      line-height: 29px;
    }
  }

  // "特徴"の画像
  &__image {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include screen-md {
      width: 100px;
      height: 100px;
    }

    &--advisor {
      background-image: url('./images/person_pen.png');
    }

    &--info {
      background-image: url('./images/person_board.png');
    }
  }

  // "特徴"のロゴ
  &__logos {
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
    margin: 15px 0;

    @include screen-md {
      gap: 32px;
      margin: 20px 0;
    }
  }

  &__logo {
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include screen-md {
      height: 50px;

      &--jpx {
        width: 45px;
      }

      &--speee {
        width: 200px;
      }
    }

    &--jpx {
      background-image: url('./images/jpx.png');
      width: 40px;
    }

    &--speee {
      background-image: url('./images/speee.png');
      width: 180px;
    }
  }

  // "特徴"のボタン
  &__buttons {
    display: flex;
    gap: 8px;
    margin-top: 10px;

    @include screen-md {
      padding-top: 20px;
    }
  }

  &__button {
    width: 150px;
    height: 40px;
    background-color: #E0F5FF;
    color: #0088FF;
    padding: 8px 12px;
    border-radius: 6px;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 11px;
      background-image: url('./images/arrow.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: auto;
    }

    &:hover {
      opacity: 0.8;
    }

    @include screen-md {
      width: 205px;
      height: 40px;
    }
  }
}
