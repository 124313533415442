@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-construction-interview {
  position: relative;
  padding-top: 60px;
  background-color: #fff;
  margin-top: 50px;
  font-family: 'Noto Sans JP', sans-serif;

  @include screen-md {
    margin-top: 0;
  }

  // 波形の装飾
  &__wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    background: url("./images/wave.svg") no-repeat center;
    background-size: cover;

    @include screen-md {
      height: 197px;
    }
  }

  &__container {
    background-color: #F0FAFF;
    margin-top: -10px;
    padding-bottom: 50px;

    @include screen-md {
      margin-top: 100px;
      position: relative;
      z-index: 1;
    }
  }

  &__container-inner {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 15px;
  }

  // タイトルと説明文
  &__main-title {
    color: #3C3C3C;
    font-size: 24px;
    line-height: 38px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;

    @include screen-md {
      font-size: 38px;
      line-height: 54px;
    }

    &-sp {
      @include screen-md {
        display: none;
      }
    }

    &-pc {
      display: none;

      @include screen-md {
        display: block;
      }
    }
  }

  &__description {
    text-align: left;
    font-size: 15px;
    line-height: 28px;
    color: #3C3C3C;
    margin-bottom: 20px;

    @include screen-md {
      font-size: 16px;
      line-height: 30px;
      color: #3C3C3C;
      margin: 0 50px 20px 50px;
    }
  }

  // 記事リスト
  &__list {
    margin-bottom: 30px;

    @include screen-md {
      display: flex;
      gap: 20px;
    }

    // 1個目の記事
    &-first-item {
      width: 100%;

      @include screen-md {
        width: 413px;
      }

      .refosumu-v2-construction-interview__image {
        height: 234px;
      }
    }

    // 2個目以降の記事
    &-second-row {
      display: flex;
      gap: 15px;
      margin-top: 23px;

      @include screen-md {
        margin-top: 0;
        width: 534px; // 267px * 2
        gap: 20px;
      }

      .refosumu-v2-construction-interview__item {
        flex: 1;
        width: auto;

        @include screen-md {
          width: 267px;
        }
      }

      .refosumu-v2-construction-interview__image {
        height: 144px;

        @include screen-md {
          height: 234px;
        }
      }

      .refosumu-v2-construction-interview__title {
        font-size: 12px;
        line-height: 20px;

        @include screen-md {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  // 記事アイテム
  &__item {
    overflow: hidden;
    transition: all 0.3s ease;
  }

  // 記事画像
  &__image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 5px;
      right: 5px;
      width: 25px;
      height: 25px;
      background: url("./images/arrow.svg") no-repeat center;
      background-size: contain;
      z-index: 1;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    &-content {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // 記事コンテンツ
  &__content {
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__text {
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.4;
  }

  // サブタイトル
  &__sub-titles {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 7px;
  }

  &__sub-title-item {
    background-color: #fff;
    font-size: 12px;
    line-height: 24px;
    color: #3C3C3C;
    white-space: nowrap;

    @include screen-md {
      font-size: 14px;
      line-height: 28px;
    }
  }

  // タイトル
  &__title {
    font-size: 16px;
    line-height: 25px;
    color: #3C3C3C;
    text-align: left;
    text-decoration: underline;

    @include screen-md {
      font-size:  18px;
      line-height: 30px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  // タグ
  &__tags {
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
  }

  &__tag {
    color: #ACACAC;
    font-size: 12px;
    line-height: 18px;
    text-align: left;

    @include screen-md {
      font-size: 14px;
      line-height: 21px;
    }
  }

  // インタビュー記事一覧
  &__more {
    width: 277px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0088FF;
    border-radius: 30px;
    background-color: #fff;
    cursor: pointer;
    margin: 0 auto;

    &-link {
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-text {
      font-size: 16px;
      line-height: 25px;
      color: #0088FF;
      text-align: center;
    }

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    &-icon {
      width: 50px;
      height: 36px;
      background: url("./images/refosumu_icon.png") no-repeat center;
      background-size: contain;
      margin-bottom: -10px;
    }
  }
}
