@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-subsidy {
  padding: 24px 16px 50px 16px;
  background-color: #FEFDE6;
  font-family: 'Noto Sans JP', sans-serif;

  @include screen-md {
    padding: 40px 0 60px;
  }

  // 内部コンテンツの最大幅を設定
  &__container {
    @include screen-md {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  // タイトルボードの調整
  &__title_board {
    margin: -20px auto 20px;

    @include screen-md {
      margin: 0 auto 25px;
    }
  }

  // 説明文
  &__description {
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 400;

    @include screen-md {
      margin-bottom: 20px;
      margin-left: 150px;
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 21px;
    margin-bottom: 21px;

    @include screen-md {
      width: 700px;
      margin: 0 auto 30px;
      gap: 30px;
    }
  }

  // 個別カード
  &__card {
    border: 1px solid #FEEC01;
    background-color: #fff;
    padding: 15px;
    position: relative;
    border-radius: 5px;

    @include screen-md {
      padding: 30px;
      min-height: 180px;
      border: 1px solid #F4F4F4;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  // カードヘッダー部分
  &__card-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    gap: 5px;

    @include screen-md {
      gap: 10px;
      margin-bottom: 15px;
    }
  }

  // カードタイトル
  &__card-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #3C3C3C;
    flex: initial;

    @include screen-md {
      font-size: 22px;
    }
  }

  // 補助金額表示
  &__card-amount {
    background-color: #FEEC01;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: bold;
    color: #3C3C3C;
    flex-shrink: 0;

    @include screen-md {
      font-size: 18px;
    }
  }

  // カード説明文
  &__card-description {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #3C3C3C;
    font-weight: 400;

    @include screen-md {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  // タグコンテナ
  &__card-tags {
    display: flex;
    gap: 11px;
    flex-wrap: wrap;
    margin-bottom: 0;
    width: calc(100% - 90px);

    @include screen-md {
      width: 100%;
    }
  }

  // 個別タグ
  &__card-tag {
    color: #3C3C3C;
    font-size: 11px;
    font-weight: 400;
    background-color: #F0FAFF;
    padding: 4px 2px;

    @include screen-md {
      font-size: 14px;
    }
  }

  // カード内の画像配置
  &__card-image {
    width: 80px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: auto;
    bottom: -20px;
    right: 15px;

    @include screen-md {
      width: 100px;
      height: 110px;
      bottom: -39px;
      right: 10px;
    }
  }
}
