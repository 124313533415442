@import 'Stylesheets/refosumu/base/v2/mixins';

.refosumu-v2-blue-line {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    top: 0;
    left: 0;
    background-image: url('./images/blue_line.png');
    background-size: cover;
  }
}

.refosumu-v2-plaid-pattern-blue {
  background-image: linear-gradient(0deg, transparent 15px, #E4F7FE 16px), linear-gradient(90deg, transparent 15px, #E4F7FE 16px);
    background-size: 16px 16px;
    border: 1px solid #f4f8f9;
    border-radius: 6px;
}

.refosumu-v2-faqs {
  padding: 0 16px 50px;

  &__title_board {
    margin: 0 auto 30px;

    @include screen-md {
      margin: 40px;
    }
  }

  &__title {
    margin: 0 auto 30px;


    @include screen-md {
      margin: 0 auto 40px;
    }

    &--pc {
      display: none;

      @include screen-md {
        display: block;
        max-width: 550px;

      }
    }

    &--sp {
      display: block;
      max-width: 345px;

      @include screen-md {
        display: none;
      }
    }
  }

  &__text-link {
    position: relative;
    display: inline-block;
    color: #0088ff;
    font-size: 12px;
    text-decoration: underline;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      right: -13px;
      width: 8px;
      height: 2px;
      background-color: #0088ff;
      transform-origin: calc(100% - 1px) 50%;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__accordions {
    max-width: 670px;
    margin: 0 auto;
  }

  &__accordion {
    margin-bottom: 10px;
    border: 1px solid #efefef;
    border-radius: 6px;
    background-color: #fff;

    &-icon {
      margin-right: 10px;
    }

    &-balloon {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      span {
        display: inline-block;
        padding: 10px 10px 10px 20px;
        font-size: 14px;
        background-color: #f0faff;
        border: 1px solid #86d8e6;
        border-radius: 0 20px 20px 10px;

        @include screen-md {
          height: 50px;
          font-size: 16px;
        }
      }
    }

    details summary {
      display: flex;
      align-items: center;
      position: relative;
      list-style: none;
      padding: 16px 58px 16px 16px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;

      @include screen-md {
        padding: 20px 76px 20px 35px;
        font-size: 18px;
      }
    }

    details summary::-webkit-details-marker {
      display: none;
    }

    details summary::after,
    details[open] summary::after {
      position: absolute;
      right: 30px;
      height: 25px;

      @include screen-md {
        right: 14px;
      }
    }

    details summary::after {
      content: url('images/accordion_plus_icon.svg');
    }

    details[open] summary::after {
      content: url('images/accordion_minus_icon.svg');
    }

    details[open] summary::before {
      position: absolute;
      content: "";
      width: calc(100% - 32px);
      bottom: 0;
      left: calc((100% - (100% - 32px)) / 2);
      height: 1px;
      border-bottom: 1px dashed #e1e1e1;

      @include screen-md {
        width: calc(100% - 56px);
        left: calc((100% - (100% - 56px)) / 2);
      }
    }


    &-content {
      display: flex;
      align-items: flex-start;
      padding: 16px;

      @include screen-md {
        padding: 20px 20px 20px 35px;
      }
    }

    &-answer {
      line-height: 1.8;

      @include screen-md {
        font-size: 16px;
      }
    }
  }
}
