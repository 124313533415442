// 文字色
$baseFont: #524b4b;

// リンク色
$linkColor: #0088ff;
$linkColor-hover: lighten($linkColor, 10%);

// 用途別各種カラー
$baseColor1: #630;

//$baseColor2: lighten($baseColor1, 10%)

$mainColor1: #e5a221;
$mainTxtColor1: #e67836;

$subColor1: #2eb0c7;

//$subColor2: lighten($subColor1, 10%)
